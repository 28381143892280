.whole-articles-container {
    margin-right: 125px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(283px, 0.24fr));
    row-gap: 45px;
    column-gap: 50px;
    margin-left: -25px;
  }
  .all-articles-no-results {
    p {
      margin-left: 97px;
      color: #6c757d;
      font-family: "Lora";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      width: 318px;
    }
  }
  .topics {
    color: #495057;
    font-family: "Lora";
    font-size: 36px;
    font-weight: 700;
    margin-top: 50px;
    margin-left: 70px;
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  .categories li {
    list-style-type: none;
    margin: 5px;
    padding: 10px;
    font-size: 16px; /* Default font size */
  }
  
  /* Responsive font sizes using relative units */
  @media (max-width: 768px) {
    .categories li {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .categories li {
      font-size: 12px; /* Further adjust font size for smaller screens */
    }
  }
  
  /* Add styles for active list item */
  .categories li.active {
    color: #d4a373;
  }
  .view-all {
    color: #495057;
    font-family: "Lora";
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  .articles-container {
    margin-top: 28px;
    margin-left: 54px;
    margin-right: 85px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(283px, 0.24fr));
    row-gap: 45px;
    column-gap: 50px;
  }
  .no-results {
    p {
      color: #6c757d;
      font-family: "Lora";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      width: 318px;
      margin-left: 17px;
    }
  }
  .article {
    position: relative;
    span {
      display: flex;
      margin-top: 15px;
      padding-bottom: 15px;
      color: #6c757d;
      font-family: "Lora";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .title {
      color: #495057;
      font-family: "Lora";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      width: 310px;
      margin-left: 0px;
    }
    .description {
      color: #6c757d;
      font-family: "Lora";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      width: 318px;
      margin-left: 0px;
    }
    .blog-type {
      width: 77px;
      height: 24px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 26px;
      left: 213px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #fff;
        text-align: center;
        font-family: "Roboto";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        z-index: 3;
      }
    }
  }
  
  .every-article {
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 310px;
    height: 567px;
    margin-top: 25px;
    margin-left: 71px;
    border-radius: 5px;
  
    &::after {
      content: "";
      position: absolute;
      top: 486px;
      left: 9px;
      height: 0.9px;
      background-color: #e5e5e5;
      width: 285.996px;
    }
    .every-title {
      color: #495057;
      font-family: "Lora";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      width: 290px;
      margin-left: 15px;
    }
    span {
      display: flex;
      margin-top: 15px;
      padding-bottom: 15px;
      color: #6c757d;
      font-family: "Lora";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 15px;
  
      &.every-description {
        color: #6c757d;
        font-family: "Lora";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 245px;
        height: 90px;
        margin-left: 15px;
      }
    }
  }
  
  .title {
    color: #495057;
    font-family: "Lora";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    width: 310px;
    margin-left: 0px;
    margin-left: 15px;
  }
  
  .blog-type {
    width: 77px;
    height: 24px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 26px;
    left: 213px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: #fff;
      text-align: center;
      font-family: "Roboto";
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      z-index: 3;
    }
  }
  
  .profile-information-container {
    position: absolute;
    top: 503px;
    left: 15px;
    img {
      margin-left: 0px;
      width: 50px;
      height: 50px;
    }
    .job-information {
      margin-left: 71px;
      margin-top: -50px;
      width: 210px;
      p {
        color: #495057;
        font-family: "Lora";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
      }
      span {
        width: 112px;
        margin-left: 1px;
        margin-top: 9px;
      }
    }
  }
  .blog-image {
    width: 310px;
    height: 280px;
    border-radius: 5px;
  }
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  