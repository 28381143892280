.brand-text {
    font-family: 'Spartan', sans-serif;
  }
  
  .nav-item-text {
    font-family: 'Roboto', sans-serif;
  }
  .navbar-custom {
    background-color: rgba(0, 0, 0, 0.2); /* Adjust alpha value to change opacity */
  }

  .logo-container {
    position: relative;
    
    &:before {
      content:  "";
      position: absolute;
      top:      calc( 100% - 2px );
      width:    90%;
      height:   3px;
      background-color: #cf0000;
      transform-origin: center center;
      transform: scaleX( 0 );
      
      animation: line-animation 3s ease infinite alternate;
    }
    
    h1#page-logo {
      font: bold 6rem 'Arial', sans-serif;
      
      animation: clip-path-reveal-1 3s ease infinite alternate;
    }
  }
  
  @keyframes line-animation {
    0% { transform: scaleX( 0 ); }
    15% { transform: scaleX( 0 ); }
    20%, 25% { transform: scaleX( 1 ); top: calc( 100% - 2px ); }
    50% { transform: scaleX( 1 ); top: 0px; }
    70% { transform: scaleX( 0.2 ); top: 0px; }
    80%, 100% { transform: scaleX( 0.2 ); top: 0px; }
  }
  
  @keyframes clip-path-reveal-1 {
    0%, 25% { clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%); }
    50% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  }