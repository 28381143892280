.editor’s {
  padding-top: 49px;
  padding-bottom: 149px;
}

.editor’s-title {
  font-family: "Lora",sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #495057;
  text-align: left;
  margin-bottom: 70px;
}

.editor’s-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.editor’s-item {
  position: relative;
width: calc((100% - 20px * 2) / 3);
}

.editor’s-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 8px;
  padding: 5px 10px;
  width: 62px;
  height: 22px;
  background: rgba(255, 255, 255, 0.15);
  border: none;
}

.editor’s-data {}

.editor’s-name {}

.editor’s-name-text {}