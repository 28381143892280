/* Custom CSS for Header component */

.header-carousel {
  position: relative;
}

.carousel-text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  padding: 50px;
}

.catbutton {
  background-color: rgba(254, 245, 245, 0.2);
  width: 110px;
  text-align: center;
  border-radius: 10px;
  padding: 5px 10px;
}

.carousel-text {
  padding: 20px;
  color: white;
}

/* Media queries for responsive design */
@media (max-width: 767.98px) {
  /* For small devices (up to 767.98px) */
  .carousel-item img {
    height: 50vh;
  }
  .header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
  }
  .carousel-text-container {
    width: 100%;
    padding: 20px;
    text-align: center; /* Center align text */
  }
  
  .carousel-text {
    text-align: center; /* Center align text */
  }
}


@media (min-width: 768px) {
  /* For large devices (768px and above) */
  .carousel-item img {
    height: 90vh;
  }

  .carousel-text-container {
    padding: 50px;
  }
}

